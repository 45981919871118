import React from 'react'
import { Link,graphql } from "gatsby"
import Img from "gatsby-image"
import "./index.scss";

import NavStatic from "../containers/navStatic/NavStatic";
import Footer from "../components/footer/Footer";
import Hero from "../images/shopping.png";
import Purchase from "../images/successful-purchase.png";
import AddToCart from "../images/add-to-cart.png";
import ReturnItem from "../images/return-item.png";
import Layout from "../components/layout";

const Shop = ({data}) => {
    console.log(data)
    const heroImg = data.heroImg.childImageSharp.fluid
    const phoneImg = data.phoneImg.childImageSharp.fluid
    const returnWomanImg = data.returnWomanImg.childImageSharp.fluid
    const fitWomanImg = data.fitWomanImg.childImageSharp.fluid
    
    return (
      <Layout>
        <NavStatic  />
        <div className="homepage-header_container">
          <div className="homepage-header_textContainer">
            <h2 className="homepage-header_title">
              {" "}
              Shop your fit{" "}
            </h2>
  
            <h3 className="homepage-header_subTitle">
              Shop with clothing size recommendations. Start shopping worry free.
            </h3>
            <div className="homepage-header_optionContanier">
              <Link  style={{marginRight:'1rem'}} className="homepage-header_button" to="/app/size-calculator-main">
                Get Started
              </Link>
              {/* <Link  className="homepage-header_button" to="/merchants">
                I'm a Merchant
              </Link> */}
            </div>
          </div>
          <div className="homepage-header_imageContainer">
            <Img  
                fluid={heroImg}
            />
            
          </div>
        </div>
  
        <div className="homepage-desktop">
          <div className="homepage-header_container">
            <div className="homepage-header_imageContainer">
         
            <Img  
                fluid={phoneImg}
            />
            </div>
            <div className="homepage-header_textContainer">
            <h2 className="homepage-featured_title">Shop your fit faster</h2>
  
              <h3 className="homepage-featured_subTitle">
                Look for the 'blue circle' on stores across the web. One-click to sign-in &  Sizify will automatically recommend the right clothing size for
                you. 
              </h3>
             
            </div>
          </div>
          <div className="homepage-header_container">
            <div className="homepage-header_textContainer">
            <h2 className="homepage-featured_title">Make sizing a sure thing</h2>
  
            <h3 className="homepage-featured_subTitle">
              Have the peace of mind knowing your clothing items will fit the
              first time.
            </h3>
            
            </div>
            <div className="homepage-header_imageContainer">
               <Img  
                fluid={fitWomanImg}
            />
            </div>
          </div>
          <div className="homepage-header_container">
            <div className="homepage-header_imageContainer">
            <Img  
                fluid={ returnWomanImg}
            />
             
            </div>
            <div className="homepage-header_textContainer">
              <h2 className="homepage-featured_title">
                Stop wasting time with returns
              </h2>
  
              <h3 className="homepage-featured_subTitle">
                Sending items back is a hassle.
              </h3>
            </div>
          </div>
        </div>
        <div className="homepage-mobile">
          <div className="homepage-header_container">
            <div className="homepage-header_textContainer">
            <h2 className="homepage-featured_title">Shop your fit faster</h2>
  
            <h3 className="homepage-featured_subTitle">
            Look for the 'blue circle' on stores across the web. One-click to sign-in & Sizify will automatically recommend the right clothing size for
                you. 
            </h3>
            </div>
            <div className="homepage-header_imageContainer">
            
            <Img  
                fluid={phoneImg}
            />
            </div>
             
          </div>
          <div className="homepage-header_container">
            <div className="homepage-header_textContainer">
            <h2 className="homepage-featured_title">
                Make sizing a sure thing
              </h2>
  
              <h3 className="homepage-featured_subTitle">
                Have the peace of mind knowing your clothing items will fit the
                first time.
              </h3>
            </div>
            <div className="homepage-header_imageContainer">
            <Img  
                fluid={fitWomanImg}
            />
            </div>
             
          </div>
          <div className="homepage-header_container">
            <div className="homepage-header_textContainer">
              <h2 className="homepage-featured_title">
                Stop wasting time with returns
              </h2>
  
              <h3 className="homepage-featured_subTitle">
                Sending items back is a hassle.
              </h3>
            </div>
            <div className="homepage-header_imageContainer">
            <Img  
                fluid={returnWomanImg}
            />
            </div>
          </div>
        </div>
        <div className="homepage-section_bg">
          <div className="homepage-section_containerCta2">
            <h2 className="homepage-header_titleCta2 ">
              Start shopping with confidence{" "}
            </h2>
            <Link className="homepage-header_button" to="/app/size-calculator-main">
              Get Started
            </Link>
          </div>
        </div>
        <Footer />
      </Layout>
    );
}

export default Shop

export const query = graphql`
query {
  heroImg: file(relativePath: { eq: "womanJumping.png" }) {
      childImageSharp {
        fluid(quality:100){
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    phoneImg: file(relativePath: { eq: "phoneImg.png" }) {
      childImageSharp {
        fluid(quality:100){
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    returnWomanImg: file(relativePath: { eq: "return woman.png" }) {
      childImageSharp {
        fluid(quality:100){
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    fitWomanImg: file(relativePath: { eq: "fit woman.png" }) {
      childImageSharp {
        fluid(quality:100){
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  
  
  
  }
`